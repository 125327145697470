<div class = "container" *ngIf="hide >= 3">
  <div class="row" style = "display: flex;justify-content: center;align-items: center;text-align: center;">
    <h2 class = "heading" style = "margin:50px 0;">
      <hr />
      Our Blog
    </h2>
  </div>
  <div class = "row">

    <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let blog of blogs  | slice:0:3">
      <div class="card" style="box-shadow: 4px rgba(0, 0, 0, 0.3);background-color: #e6e6f2;border-radius: 10px;margin: 30px 0px;">
        <div class="card-body">
          <div style="display: flex;flex-direction: column;justify-content: center;align-items: center;">
            <img [src]="blog.coverImage" class="img-fluid" style="padding: 8px 16px;height: 200px;width: 300px;">
            <h6 style="margin: 8px 0px;">{{ blog.heading }}</h6>
          </div>
          <p class="para" style="margin-bottom: 20px;">
            {{ blog.subheading }}
          </p>
          <div style = "text-align: center;">
            <p style="margin-bottom: 10px;cursor: pointer;font-size: 16px;color: rgba(110, 108, 239, 1);" (click)="navigateToBlog(blog.id)">Read More</p>
          </div>
          <div style = "display: flex;justify-content: space-around;align-items: center;margin-top: 20px;">
            <!-- <button *ngIf="isAuthenticated" class = "btn btn-primary" (click)="openEditBlogDialog(blog.id)">Edit</button>
            <button *ngIf="isAuthenticated" class = "btn btn-danger" (click)="deleteBlog(blog.id)">Delete</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
