<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-sticky bg-white" id="navbar" style="box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);">
  <div class="container-fluid" style = "padding-right: 0px;">
    <a class="navbar-brand logo">
      <img style="height: 1.4rem;margin-left: 24px;cursor: pointer;" routerLink="/home" fragment="home" class="logo2" src=" assets/images/logos/logo-grad.png">
    </a>
    <button class=" navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse03"
      aria-controls="navbarCollapse03" aria-expanded="false" aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed" style="margin-right: 10px;">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarCollapse03" [ngbCollapse]="isCollapsed">

      <ul class = "navbarList" id="mySidenav">
        <li class = "nav-item">
          <a class = "nav-link" style = "color: rgba(74, 85, 104, 1);" [routerLink]="['/home']" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">Home</a>
        </li>
        <li class = "nav-item">
          <a class = "nav-link" style = "color: rgba(74, 85, 104, 1);white-space: nowrap;" [routerLink]="['/moreinfo/about']" routerLinkActive="active-link">About Us</a>
        </li>
        <li class = "nav-item">
          <a class = "nav-link" style = "color: rgba(74, 85, 104, 1);white-space: nowrap;" [routerLink]="['/moreinfo/products']" routerLinkActive="active-link">Product</a>
        </li>
        <li class = "nav-item">
          <a class = "nav-link" style = "color: rgba(74, 85, 104, 1);" [routerLink]="['/moreinfo/industry']" routerLinkActive="active-link">Industry</a>
        </li>
        <li class = "nav-item">
          <a class = "nav-link" style = "color: rgba(74, 85, 104, 1);" [routerLink]="['/blogging/blog-list']" routerLinkActive="active-link">Blog</a>
        </li>
        <!-- <li class = "nav-item">
          <a class = "nav-link" style = "color: rgba(74, 85, 104, 1);" [routerLink]="['/moreinfo/careers']" routerLinkActive="active-link">Careers</a>
        </li> -->
        <li class = "nav-item">
          <a class = "nav-link" style = "color: rgba(74, 85, 104, 1);" [routerLink]="['/moreinfo/contact']" routerLinkActive="active-link">Contact</a>
        </li>
        <li class = "nav-item">
          <span class = "nav-link">
            <button class = "appbutton" style = "height: 33px;display: flex;align-items: center;white-space: nowrap;outline: none;border-radius: 4px;"
              [routerLink]="['/moreinfo/request-for-demo']">
              Request for a Demo
            </button>
          </span>
        </li>
      </ul>
    </div>
  </div>
</nav>
<router-outlet></router-outlet>


