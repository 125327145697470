<div appScrollspy>

  <section id="home">
    <div class="container-fluid">
      <ngb-carousel #carousel [interval]="0" (slide)="onSlide($event.current)" class="custom-carousel">
        
        <ng-template ngbSlide *ngFor="let slide of slides; let i = index">
          <div class="row align-items-center"> <!-- Center vertically -->
            <div class="col-sm-12 col-md-12 col-lg-6 left-section">
              <div class="heading">
                <span [innerHTML]="slide.title"></span>
              </div>
              <div class="subHeading">
                {{ slide.subtitle }}
              </div>
              <div class="mobileApp">
                <button class="btn btn-warning started" (click)="openLink(slide.link)">
                  Let's get started!
                </button>
              </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 right-section">
              <div class="carousel-container">
                <div class="custom-carousel  d-flex justify-content-center align-items-center">
                  <img [src]="slide.image" [alt]="slide.alt" class="img-fluid sliderImages">
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        
      </ngb-carousel>
    </div>
  </section>
  
  
</div>

<app-whyiceiptwala></app-whyiceiptwala>
<app-servicewala></app-servicewala>
<app-aboutwala></app-aboutwala>
<app-clientwala></app-clientwala>
<app-featurewala></app-featurewala>
<app-footerwala></app-footerwala>


<df-messenger
  intent="WELCOME"
  chat-title="iCopilot"
  agent-id="1dc2b77e-2a0e-45f9-bfa7-84ae9c868047"
  language-code="en"
  chat-icon="../../assets/images/icon/chat.png"
></df-messenger>


<!-- <section style = "padding: 50px 0 0 !important;" id="home">
  <div class="container-fluid">

    <div class="row">

      <div class="col-sm-12 col-md-12 col-lg-6 left-section">
        <div class = "heading" style = "margin: 70px 0px 0px 50px;">
          <span>"Introducing the world's <span style = "color: rgba(252, 118, 67, 1);">first ERP</span> system <br />
            empowered by cutting-edge <span style = "color: rgba(252, 118, 67, 1);">AI</span> technology."</span>
        </div>
        <br />
        <div class = "subHeading" style = "margin: 35px 90px 0px 50px;">
          "A digital platform designed to simplify your life, offering an efficient solution for managing all your business needs in one place. We prioritize your time and privacy, ensuring a seamless experience."<br />
        </div>
        <div class = "mobileApp" style = "margin: 70px 0px 10px 38px;">
          <button class="btn"
            onclick=" window.open('https://web.iceipts.com/#/auth/login','_blank')" target="_blank">
              <span class = "btn btn-warning"
              style = "height: 33px;display: flex;align-items: center;">
              Let's get started !</span>
          </button>
          <button class="btn"  (click)="openPopup()">
              <span class = "getStarted">Mobile App</span>
          </button>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-6 right-section">
        <div class="carousel-container">
          <div class="custom-carousel" style = "display: flex;justify-content: center;align-items: center;">
              <img src="../../assets/images/slider-1.png" alt="Iceipts ERP" class="sliderImages">
          </div>
        </div>
      </div>

    </div>

  </div>
</section> -->


<!-- <div *ngIf="showPopup" class="modal-overlay">
  <div class="popup-overlay">
    <div class="popup-content">
      <h2>Coming Soon</h2>
      <p>Our mobile app is under development. Stay tuned!</p>
      <button class = "btn btn-warning" (click)="closePopup()">Close</button>
    </div>
  </div>
</div> -->