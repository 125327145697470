<!-- <section>
  <div class = "container-fluid">
    <div class = "commentSection">
      <hr />
      <h2 class = "heading">Why Customers love <br /> working with us</h2>
      <p class="comment">
        {{ currentCustomer?.customerComment }}
      </p>
      <img src = "../../../../assets/images/arrow-left.png" alt = "left-arrow" class = "leftArrow" (click)="onArrowClick('left')">
      <img src = "../../../../assets/images/arrow-left.png" alt = "right-arrow" class = "rightArrow" (click)="onArrowClick('right')">
    </div>
    <div class = "customerSection">
      <div class = "customers" *ngFor="let item of customer; let i = index" (slide)="onSlide($event.current)">
        <img [src]="item.customerImage" alt="" class="img-fluid d-block rounded-circle" width="150" height="150" [ngClass]="{'active': i === currentSlide, 'inactive': i !== currentSlide}">
        <h6 class="customerName" style = "font-size: 18px;color: #000;" [ngClass]="{'active': i === currentSlide, 'inactive': i !== currentSlide}">{{ item.customerName }}</h6>
        <p class="customerOccupation" style = "font-size: 16px;color:#000;" [ngClass]="{'active': i === currentSlide, 'inactive': i !== currentSlide}">{{ item.customerOccupation }}</p>
      </div>
    </div>
  </div>
</section> -->




<section>
  <div class="container-fluid">
    <hr class="divider" />
    <h2 class="heading">Why Customers love <br /> working with us</h2>
      
    <div class="testimonial-container mx-5">
      <button class="prev-btn" *ngIf="testimonials.length > 4" (click)="prevSlide()">&#10094;</button>
  
      <div class="testimonial-wrapper py-3" #testimonialWrapper>
        <div class="testimonial-card" *ngFor="let testimonial of testimonials">
          <div class="testimonial-header">
            <div class="job-initials">
              {{ getJobInitials(testimonial?.name) }}
            </div>
            <!-- <img [src]="testimonial.image" alt="User Image" class="testimonial-img"> -->
            <div class="testimonial-info">
              <h4>{{ testimonial.name }}</h4>
              <div class="stars">
                <span *ngFor="let star of [1,2,3,4,5]">⭐</span>
              </div>
            </div>
            <img src="assets/images/commas.png" alt="User Image" class="comma-img">
          </div>
          <hr class="line">
          <p class="testimonial-text">{{ testimonial.feedback }}</p>
        </div>
      </div>
  
      <button class="next-btn" *ngIf="testimonials.length > 4" (click)="nextSlide()">&#10095;</button>
    </div>
  </div>  
</section>
