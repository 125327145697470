import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-clientwala',
  templateUrl: './clientwala.component.html',
  styleUrls: ['./clientwala.component.css']
})
export class ClientwalaComponent implements OnInit {

  @ViewChild('testimonialWrapper', { static: false }) testimonialWrapper!: ElementRef;

  testimonials = [
    {
      name: "GeetaRani Mines",
      image: "assets/images/user/img-2.jpg",
      feedback: "This ERP solution has transformed our workflow! The automated processes and real-time data insights have helped us make faster, smarter decisions."
    },
    {
      name: "RadhaKrishnan, FIFM CEO",
      image: "assets/images/user/client-pic-8.jpg",
      feedback: "Easy to use and highly effective. We’ve saved hours of manual work and improved our reporting process."
    },
    {
      name: "Tone Me",
      image: "assets/images/user/img-1.jpg",
      feedback: "A game-changer! Our efficiency and productivity have increased significantly thanks to the seamless integration."
    },
    {
      name: "Aravind Narayan",
      image: "assets/images/user/client-pic-4.jpg",
      feedback: "Fantastic customer support! Any issues we faced were resolved quickly, making the experience even better."
    },
    // {
    //   name: "William Clark",
    //   image: "assets/images/user/client-pic-6.jpg",
    //   feedback: "The automation features are a huge time-saver. We’ve optimized our entire workflow efficiently!"
    // }
  ];

  constructor() { }

  ngOnInit(): void {
    // this.updateCurrentCustomer();
  }

  prevSlide() {
    this.testimonialWrapper.nativeElement.scrollBy({ left: -340, behavior: 'smooth' });
  }

  nextSlide() {
    this.testimonialWrapper.nativeElement.scrollBy({ left: 340, behavior: 'smooth' });
  }

  getJobInitials(name: string): string {
    if (!name) return '';

    const words = name.split(' ').filter(word => word.length > 0);
    if (words.length >= 2) {
      return (words[0][0] + words[1][0]).toUpperCase();
    } else if (words.length === 1) {
      return words[0][0].toUpperCase();
    }
    return '';
  }

  // customer = [
  //   {
  //     customerName : "Ganesh Kumar",
  //     customerOccupation : "CEO",
  //     customerImage : 'assets/images/user/pic-1.png',
  //     customerComment : `Absolutely fantastic! I wholeheartedly endorse iceipts Solutions as an outstanding ERP agency. Among the top-tier agencies I've encountered. I wouldn't hesitate to recommend their services to anyone.`
  //   },
  //   {
  //     customerName : "Ajay Raut",
  //     customerOccupation : "Co-Founder",
  //     customerImage : 'assets/images/user/pic-2.png',
  //     customerComment : `Incredible experience! I can confidently vouch for iceipts Solutions as a premier ERP agency. Undoubtedly one of the finest agencies I've ever worked with. I'd gladly refer their services to others.`
  //   },
  //   {
  //     customerName : "Dev Patil",
  //     customerOccupation : "CEO",
  //     customerImage : 'assets/images/user/pic-3.png',
  //     customerComment : `Exceptional service! I unequivocally recommend iceipts Solutions as a leading ERP agency. One of the most impressive agencies I've ever encountered. I'd eagerly endorse their work to others.`
  //   },
  //   {
  //     customerName : "Dilip Sharma",
  //     customerOccupation : "Co-Founder",
  //     customerImage : 'assets/images/user/pic-4.png',
  //     customerComment : `Outstanding performance! I have no reservations in recommending iceipts Solutions as an exemplary ERP agency. Truly one of the best agencies I've come across. I'd enthusiastically promote their services.`
  //   },
  //   {
  //     customerName : "Amisha Pandey",
  //     customerOccupation : "CEO",
  //     customerImage : 'assets/images/user/pic-5.png',
  //     customerComment : `Remarkable expertise! I am thoroughly impressed by iceipts Solutions as an exceptional ERP agency. Among the very best agencies I've had the pleasure of working with. I wouldn't hesitate to advocate for their work.`
  //   },
  // ]

  // currentSlide = 2;
  // currentCustomer: any;

  // onSlide(slideEvent: any) {
  //   this.currentSlide = slideEvent;
  //   this.updateCurrentCustomer();
  // }

  // onArrowClick(direction: 'left' | 'right') {
  //   if (direction === 'left') {
  //     this.currentSlide = (this.currentSlide - 1 + this.customer.length) % this.customer.length;
  //   } else {
  //     this.currentSlide = (this.currentSlide + 1) % this.customer.length;
  //   }
  //   this.updateCurrentCustomer();
  // }

  // updateCurrentCustomer() {
  //   this.currentCustomer = this.customer[this.currentSlide];
  // }


}
