import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  id: string;
  fragment: any;
  @ViewChild('carousel', { static: false }) carousel!: NgbCarousel;

  slides = [
    {
      image: '../../assets/images/ats_image.png',
      title: 
        `"<span class="highlight">AI-Driven ATS</span> Streamline Recruitment, Secure Top Talent Faster"`,
      subtitle: "Empower your hiring process with our AI-powered ATS, designed to streamline recruitment from sourcing to onboarding. Leverage intelligent automation to identify top talent faster, reduce manual effort, and make data-driven hiring decisions with ease.",
      alt: 'ATS',
      link: 'https://web.iceipts.com/#/auth/login'
    },
    {
      image: '../../assets/images/interview-image.png',
      title: 
      `"<span class="highlight">AI-Powered Interviews:</span> Smarter Hiring, Faster Decisions!"`,
      subtitle: "Our AI-powered HRMS conducts seamless, unbiased interviews by analyzing candidate responses in real time. It evaluates skills, sentiment, and qualifications using advanced algorithms, ensuring fair and data-driven hiring. With automated scheduling and adaptive questioning, it streamlines the hiring process like never before.",
      alt: 'AI-Powered Interviews',
      link: 'https://web.iceipts.com/#/auth/login'
    },
    {
      image: '../../assets/images/hrmsnew.png',
      title: 
      `"Streamline Your Workforce with <span class="highlight">AI-Powered HRMS</span> Efficiency"`,
      subtitle: "Unlock the power of intelligent workforce management with our AI-powered HRMS. Automate processes, enhance decision-making, and improve employee experiences—all in one smart solution designed to drive productivity and growth.",
      alt: 'HRMS',
      link: 'https://web.iceipts.com/#/auth/login'
    },
    {
      image: '../../assets/images/slider-2.png',
      title: 
      `"Introducing the world's <span class="highlight">first ERP</span> system empowered by cutting-edge <span class="highlight">AI</span> technology."`,
      subtitle: "A digital platform designed to simplify your life, offering an efficient solution for managing all your business needs in one place. We prioritize your time and privacy, ensuring a seamless experience.",
      alt: 'Iceipts ERP',
      link: 'https://web.iceipts.com/#/auth/login'
    },
    {
      image: '../../assets/images/transport.png',
      title: 
      `"Smart <span class="highlight">Transportation Solutions</span> Streamline Operations, Accelerate Success"`,
      subtitle: "Optimize your transportation operations with our smart management solution. From real-time tracking to efficient route planning, our system empowers you to reduce costs, improve delivery timelines, and enhance overall fleet performance.",
      alt: 'Transport',
      link: 'https://web.iceipts.com/#/auth/login'
    },
  ];
  currentSlide = 0;


  // showPopup = false;

  // images = [
  //   '../../assets/images/slider-1.png',
  //   '../../assets/images/slider-2.png',
  //   '../../assets/images/slider-3.png',
  //   '../../assets/images/slider-4.png',
  //   '../../assets/images/slider-5.png',
  // ];
  // currentSlide = 1;
  // totalSlides = 4;
  // progressPercentage = 20;
  // temp: any = 0;
  // maxProgressWidth: any;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment: string) => {
      this.fragment = fragment;
      if (this.fragment) {
        setTimeout(() => {
          this.scroll(this.fragment);
        }, 250);
      }
    });
  }

  selectSlide(index: number) {
    if (this.carousel) {
      this.carousel.select(index.toString()); // Convert number to string for compatibility
      this.currentSlide = index;
    }
  }

  onSlide(event: number) {
    this.currentSlide = event;
  }

  openLink(url: string) {
    window.open(url, '_blank');
  }

  // onSlide(slideEvent: any) {
  //   this.currentSlide = slideEvent;
  //   this.updateProgressBar();
  // }

  // openPopup() {
  //   this.showPopup = true;
  //   document.body.style.overflow = 'hidden'; // Prevent scrolling
  // }

  // closePopup() {
  //   this.showPopup = false;
  //   document.body.style.overflow = ''; // Allow scrolling
  // }

  // updateProgressBar() {
  //   const value = String(this.currentSlide);
  //   this.temp = value.slice(-1);
  //   this.maxProgressWidth = 425;
  //   const rawPercentage = ((this.temp + 1) / this.totalSlides) * 10 + 20;
  //   this.progressPercentage = Math.min(rawPercentage, this.maxProgressWidth);
  // }

  scroll(id) {
    const elmnt = document.getElementById(id);
    elmnt.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }
}
