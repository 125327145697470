import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  private apiUrl = environment.apiUrl;
  private hrmsBaseUrl = environment.hrmsBaseUrl;

  private leadAddedSource = new Subject<void>();
  leadAdded$ = this.leadAddedSource.asObservable();

  notifyLeadAdded() {
    this.leadAddedSource.next();
  }

  constructor(private http: HttpClient) {}

  // Get all jobs
  getJobs(size: number, page: number): Observable<any> {
    console.log("getJobs", size, page);
    return this.http.get(`https://hrmsbackend.iceipts.com/hrmsserver/job/getJobList`, {
      params: {
        size: size.toString(),
        page: page.toString()
      }
    });
  }

  // Get job by ID
  getJobById(jobId: string): Observable<any> {
    return this.http.get(`https://hrmsbackend.iceipts.com/hrmsserver/job/detail/${jobId}`);
  }

  // Create a new blog
  createBlog(blogData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}users/postBlog`, blogData);
  }

  // Get all blogs
  getAllBlogs(size: number, page: number): Observable<any> {
    return this.http.get(`${this.apiUrl}users/getAllBlogs`, {
      params: {
        size: size.toString(),
        page: page.toString()
      }
    });
  }

  // Get blog by ID
  getBlogById(blogId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}users/getBlogById/${blogId}`);
  }

  // Update a blog
  updateBlog(blogId: string, blogData: any): Observable<any> {
    return this.http.put(`${this.apiUrl}users/updateBlog/${blogId}`, blogData);
  }

  // Delete a blog
  deleteBlog(blogId: string): Observable<any> {
    return this.http.delete(`${this.apiUrl}users/deleteBlog/${blogId}`);
  }

  // Image Upload
  blogImages(formdata: {}): Observable<any> {
    return this.http.post(`${this.apiUrl}users/blogImages`, formdata);
  }
}
